function akelCurtain(site_id, url) {
	var src = $j('#curtains').find('[data-site="'+site_id+'"]');
	var lbar = src.find('.loader-bar');
	eraseCookie('revisit');
	$j('#curtains').find('[data-site="'+site_id+'"]').fadeIn(300);
	setTimeout(function() {
		lbar.fadeTo(50, 1);
		lbar.removeClass('start');
	}, 50);
	$j('#iAtlasChatDiv').fadeOut(100);
	$j('.acsb-trigger').fadeOut(100);
	setTimeout(function() {
		lbar.addClass('animate');
	}, 350);
	setTimeout(function() {
		src.find('.content').fadeTo(300, 1);
	}, 150);
	setTimeout(function() {
		document.location.href=( url );
	}, 450);
	bLazy.revalidate();
	return;
}

function nav() {

    var fadetime = 250;

    $j('a.share').click(function(e){
        var el = $j(this);
        var shareurl = el.attr('href');
        e.preventDefault();
        open_window(shareurl,750,500);
        return false;
    });


	$j('.scroll-target').prepend('<span class="anchor"><a id="content" class="target"></a></span>');

    if ( !! $j('#qsl a').length && window.location.hash == '#schedule' ) {
        $j('#qsl a').click();
    }

    $j('.back-to-top').click(function(){
        scrollToTop();
    });

    $j('.top-nav.tr li:not(.menu-item-has-children) a:not([target="_blank"]), a.top-logo, a.sd-logo, #akel-nav .akel-nav li:not(.menu-item-has-children) a:not([target="_blank"])').click(function(e){
        e.preventDefault();
        var el = $j(this);
        var url = el.attr('href');
		var site_parent = el.parents('.top-nav').data('site');
		var site = el.parent().data('site') || site_parent || 1;
        setTimeout(function() {
			akelCurtain(site, url);
        }, 440);
    });

    $j(document).on('click','.top-nav.tr li.menu-item-has-children>a:not([target="_blank"])', function(e){
        e.preventDefault();
        var el = $j(this);
        if ( el.parent().hasClass('open') ) {
            el.parent().removeClass('open');
            el.find('span.fa-minus').addClass('fa-plus').removeClass('fa-minus');
            el.find('span.fa-caret-up').addClass('fa-caret-down').removeClass('fa-caret-up');
        } else {
            $j('.top-nav li.open').removeClass('open');
            el.find('span.fa-caret-down').addClass('fa-caret-up').removeClass('fa-caret-down');
            el.parent().addClass('open');
        }
        let topwidget = $j('.top-nav');
        let closeTimer;
        topwidget.hover(
            function () {
                clearTimeout(closeTimer);
            },
            function () {
                closeTimer = setTimeout(function () {
                    if ( sizer > 21 ) {
                        $j('a:focus').blur();
                        $j('.top-nav li.open').find('span.fa-caret-up').addClass('fa-caret-down').removeClass('fa-caret-up');
                        $j('.top-nav li.open').removeClass('open');
                        $j('.top-nav li.focus').removeClass('focus');
                    }
                }, 600);
            }
        );
    });

    $j('a[data-site], body.community .fs-contact li a, body.community .fs-home a').click(function(e){
        e.preventDefault();
        var el = $j(this);
        var url = el.attr('href');
		var site = el.data('site') || 1;
        if ( $j(window).width() > 980 ) {
			if ( site == 1 ) {
            	//$j('body').addClass('hide-top animating');
			}
        } else {
            //$j('#oc-akel-btn').click();
        }
        $j('.hero h2, .hero h1').addClass('unload');
        $j('div.loader').fadeIn(150);
        setTimeout(function() {
			akelCurtain(site, url);
        }, 440);
		return false;
    });

    $j('.community-link').click(function(e){
        e.preventDefault();
        var el = $j(this);
        var goLink = el.attr('href');
        ////$j('body').addClass('show-top');
        setTimeout(function() {
            document.location.href=( goLink );
        }, 50);
    });

    $j('#nav li.ignore a').click(function(e){
        e.preventDefault();
    });

    $j('#nav li.schedule a').click(function(e){
        e.preventDefault();
        $j('#qsl>a').click();
    });

    $j('.nav-icon a').click(function(e){
        $j('.hero h2, .hero h1').addClass('unload');
        $j('div.loader').fadeIn(150);
    });
    $j('#community_phone_numbers').change(function(e){
        e.preventDefault();
        var el = $j(this);
        var selected = el.find('option[value="'+el.val()+'"]');
        $j('#phone-nav-number').html(selected.data('phone'));
        $j('#phone-nav-number').attr('href',selected.data('link'));
        $j('#phone-nav-hours').html(selected.data('hours'));
        if ( selected.data('status') ) {
            $j('.phone-nav-location').html(selected.data('location')+' &middot; '+selected.data('status'));
        }
        if ( selected.data('location') && ! selected.data('status') ) {
            $j('.phone-nav-location').html(selected.data('location'));
        }
        if ( !selected.data('location') && ! selected.data('status')) {
            $j('.phone-nav-location').html($j('.phone-nav-location').data('default'));
        }
        if ( selected.data('logo') ) {
            $j('.phone-nav-logo').html('<img src="'+selected.data('logo')+'" width="'+selected.data('logo-width')+'" alt="">');
        } else {
            $j('.phone-nav-logo').html('');
        }
    });
    $j('#community_phone_numbers_mobile').change(function(e){
        e.preventDefault();
        var el = $j(this);
        var selected = el.find('option[value="'+el.val()+'"]');
        $j('#phone-nav-number-mobile').html(selected.data('phone'));
        $j('#phone-nav-number-mobile').attr('href',selected.data('link'));
        $j('#phone-nav-number-tablet').html(selected.data('phone'));
        $j('#phone-nav-number-tablet').attr('href',selected.data('link'));
        $j('#phone-nav-hours-mobile').html(selected.data('hours'));
    });
    //$j("#community_phone_numbers").prop("selectedIndex", 1);

	$j('#nav li.menu-item-has-children').hover(function(){
        if ( ! $j('body').hasClass('oc-open') ) {
			//$j('.hero .cover').stop(true, false).fadeTo(180,.9);
		}
	},function(){
        if ( ! $j('body').hasClass('oc-open') ) {
			//$j('.hero .cover').stop(true, false).fadeTo(180,.4);
		}
	});
	$j(document).on('click','#nav li.menu-item-has-children', function(){
        if ( $j('body').hasClass('oc-open') ) {
            return;
        }
	});
	$j('#nav li:not(.blank, .ignore, .schedule) a, #akel-nav li:not(.blank, .ignore, .schedule) a').mouseenter(function(){
		bLazy.revalidate();
	});


    $j('li.menu-item-has-children a')
	.blur(function() {
        var link = $j(this);
        var dropdown = link.siblings('ul');
		$j(this).parents('li.menu-item-has-children').removeClass("focus");
        //dropdown.attr('inert', '');
	})
	.focus(function() {
		$j(this).parents('li.menu-item-has-children').addClass("focus");
        var link = $j(this);
        var dropdown = link.siblings('ul');
        //dropdown.removeAttr('inert');
	});

    $j('#nav .actions>p a:not([target="_blank"])').click(function(e){
	  	e.preventDefault();
        var el = $j(this);
        var goLink = el.attr('href');
        var fadetime = 250;
        $j('#site-overlay').fadeOut(100);
        $j('body.oc-open').removeClass('oc-open');
        fadetime = 360;
        setTimeout(function() {
            document.location.href=( goLink );
        }, fadetime);
	});

	$j('.akel-phone>li>a').click(function(e){
        e.preventDefault();
        var el = $j(this);
		if ( el.parent().hasClass('open') ) {
		}
        if ( $j(window).width() > 980 ) {
			//log('desktop click');
		} else {
			//log('mobile click');
            if ( el.parent().hasClass('open') ) {
                el.find('span').text('Call Now');
                el.parent().removeClass('open');
                return;
            } else {
                el.find('span').text('Close X');
            }
		}
		el.parent().addClass('open');
		return false;
	});

    $j('#akel-nav .actions>p a:not([target="_blank"])').click(function(e){
	  	e.preventDefault();
        var el = $j(this);
        var goLink = el.attr('href');
		akelCurtain(1,goLink);
	});
    var navtimer = 0;
    var sizer = $j('#msizer').width();

    //$j('#nav .nav li.menu-item-has-children').find('ul').fadeOut(1);
    $j(document).on('click', '#nav .nav li:not(.blank, .ignore, .schedule) a:not([target="_blank"]), #akel-nav .akel-nav li:not(.blank, .ignore, .schedule) a:not([target="_blank"])', function(e){
        e.preventDefault();
        var el = $j(this);
        var goLink = el.attr('href');
		var navtype = 'akelnav';
		var meganav = false;
        sizer = $j('#msizer').width();
        if ( el.parents('#nav').length ) {
			navtype = 'nav';
		}
        if ( el.parents('#nav.mega').length ) {
			meganav = true;
		}
        if ( $j('#nav').hasClass('animating') ) {
			return;
		}
        if (
            el.parent().hasClass('menu-item-has-children') && meganav ||
            el.parent().hasClass('menu-item-has-children') && $j('body.oc-open').length ||
            el.parent().hasClass('menu-item-has-children') && $j('body.oc-akel-open').length
        ) {
            clearTimeout(navtimer);
            var sizer = $j('#msizer').width();
            // Toggle sub menus when nav open
            if ( el.parent().hasClass('open') ) {
                // Close Current Menu if Open
                if (
                    (el.parent().hasClass('communities') ||
                     el.parent().hasClass('find-a-home')) &&
                    sizer <= 21
                ) {
				    comnavOutro(navtype);
				} else {
	                el.find('span.fa-minus').addClass('fa-plus').removeClass('fa-minus');
                    el.find('span.fa-caret-up').addClass('fa-caret-down').removeClass('fa-caret-up');
                    if ( sizer > 21 ) {
    	                el.next('ul').hide();
                    } else {
                        el.next('ul').stop(true,false).slideUp(500, 'easeInOutExpo');
                    }
				}
                el.parent().removeClass('open');
                if ( sizer > 21 ) {
    			    $j('.hero .cover').stop(true, false).fadeTo(180,.4);
                }
            } else {
                if ( sizer > 21 ) {
                    $j('.hero .cover').stop(true, false).fadeTo(180,.9);
                }
                // Close any open Sub Menu
            	if ( ! $j('#nav.com-open').length || ! $j('#akel-nav.com-open').length) {
                    el.parents('.menu').find('li.open').find('span.fa-minus').addClass('fa-plus').removeClass('fa-minus');
                    el.parents('.menu').find('li.open').find('span.caret-up').addClass('caret-down').removeClass('caret-up');
                    if ( sizer > 21 ) {
    	                el.next('ul').show();
                        el.parents('.menu').find('li.open a').next('ul').hide();
                        el.find('span.fa-caret-down').addClass('fa-caret-up').removeClass('fa-caret-down');
                    } else {
                        el.find('span.fa-plus').addClass('fa-minus').removeClass('fa-plus');
                        el.next('ul').stop(true,false).slideDown(500, 'easeInOutExpo');
                        el.parents('.menu').find('li.open a').next('ul').stop(true,false).slideUp(500, 'easeInOutExpo');
                    }
				}
            	el.parents('.menu').find('li.open').removeClass('open');
                el.parent().addClass('open');

                if (
                    (el.parent().hasClass('communities') ||
                     el.parent().hasClass('find-a-home')) &&
                    sizer <= 21
                ) {
				    comnavIntro(navtype);
				} else {
	            	if ( $j('#nav.com-open').length || $j('#akel-nav.com-open').length ) {
						var timer = 0;
						var timer = 500;
                        if ( sizer <= 21 ) {
                            comnavOutro(navtype);
			                el.find('span.fa-plus').addClass('fa-minus').removeClass('fa-plus');
                            el.next('ul').stop(true,false).slideDown(500, 'easeInOutExpo');
                        } else {
                            el.next('ul').stop(true,false).fadeIn(150);
                        }
						if ( ! el.parent().hasClass('community') ) {
							timer = 0;
						}

					} else {
						$j('#nav.com-open, #akel-nav.com-open').removeClass('com-open slide');
					}
				}
            }
        } else {
            if ( el.hasClass('community-link') )  {
                //$j('body').addClass('show-top');
            }
            $j('#nav li.current-menu-item, #akel-nav li.current-menu-item').removeClass('current-menu-item');
            el.parent().addClass('current-menu-item');
            $j('#back-to-akel').addClass('scrolled');
            if ( el.parent().hasClass('c-nav') || el.parents('#akel-nav').length )  {
				var site = el.parent().data('site') || 1;
				akelCurtain(site, goLink);
				return;
            }

            $j('div.loader').fadeIn(150);
            $j('.hero h2, .hero h1').addClass('unload');
            fadetime = 250;
            if( !! $j('body.oc-open').length ) {
                $j('#site-overlay').fadeOut(100);
                $j('body.oc-open').removeClass('oc-open');
                fadetime = 360;
            }
            if( !! $j('body.oc-akel-open').length ) {
                $j('#site-overlay').fadeOut(100);
                $j('body.oc-akel-open').removeClass('oc-akel-open');
                fadetime = 360;
            }
            setTimeout(function() {
                document.location.href=( goLink );
            }, fadetime);
        }

        let widget = $j('#nav');
        let closeTimer;
        widget.hover(
            function () {
                clearTimeout(closeTimer);
            },
            function () {
                closeTimer = setTimeout(function () {
                    if ( sizer > 21 ) {
                        //return;
                        $j('a:focus').blur();
                        $j('#nav li.open').find('span.fa-caret-up').addClass('fa-caret-down').removeClass('fa-caret-up');
                        $j('#nav li.open').removeClass('open');
                        $j('#nav li.focus').removeClass('focus');
                        $j('.hero .cover').stop(true, false).fadeTo(180,.4);
                    }
                }, 600);
            }
        );
    });

    $j(document).on('click', '.curtain', function(){
		//$j('.curtain').fadeOut();
		//$j('.curtain .loader-bar').removeClass('animate loaded').addClass('start');
		//$j('.curtain .content-bar').fadeTo(1, 0);
    });

    // Set Active Menu for Dropdown
    //$j('#nav li.menu-item-has-children ul>li>a').append('<span class="fas fa-angle-right ddarrow mobile-hide"></span>');

    if ( sizer < 21 ) {
        $j('#nav li.current_page_parent>a').find('span.fa-plus').addClass('fa-minus').removeClass('fa-plus');
        $j('#nav li.current_page_parent').addClass('open');
        $j('#nav li.current_page_parent a').next('ul').slideDown(10);
    }

    $j('#menu-item-204 a').addClass('community-link');
    //$j('#fnav').find('li.current-menu-item').parents('.navgroup').addClass('active');

    $j('.scrolltop').click(function(){
        scrollToTop();
        return false;
    });

    $j('.aa').anchorAnimate();
    $j('.sub-nav ul.nav li a').anchorAnimate();

}

$j.fn.anchorAnimate = function() {
    return this.each(function() {

        var caller = this;

        $j(caller).click(function(e) {

            e.preventDefault();

            $j('body').addClass('scrolled scrolling started');

            $j(caller).parent().parent().find('.active').removeClass('active');
            $j(caller).parent().addClass('active');

            var locationHref = window.location.href;
            var elementClick = $j(caller).attr("href");

            var destination = $j(elementClick).offset().top || 0;

            $j("html:not(:animated),body:not(:animated)").animate({
                scrollTop: destination
            }, 1200, 'easeInOutExpo', function() {
                setTimeout(function(){
                    $j('body').addClass('scrolled');
                    $j('body').removeClass('scrolling');
                    if ( !$j('body.admin-bar').length ) {
                        window.location.hash = elementClick;
                    }
                }, 100);
            });

            return true;

        });
    });
};

(function (window, $) {

    $(function() {

        $(document).on('click', '.btn, .ripple, .full-nav a, #nav>nav>ul>li>a, #nav>nav>ul>li>ul>li>a>span.link, .top-nav li>a', function (event) {

			$(this).addClass('rippling');
            var $div = $('<span class="ripple" />'),
            btnOffset = $(this).offset(),
            xPos = event.pageX - btnOffset.left,
            yPos = event.pageY - btnOffset.top;

            $div.addClass('ripple-effect');
            var $ripple = $(".ripple-effect");

            $ripple.css("height", $(this).height());
            $ripple.css("width", $(this).height());
            $div
            .css({
                top: yPos - ($ripple.height()/2),
                left: xPos - ($ripple.width()/2),
                background: $(this).data("ripple-color")
            })
            .appendTo($(this));

            window.setTimeout(function(){
                $div.remove();
            }, 1400);
        });

    });

})(window, jQuery);

