var cnavout, cnavin, accentinview, hidenav, shownav, accentoutview, clearloader;
function comnavOutro(type) {
    var nav = $j('#nav');
    if (type != 'nav') {
        var nav = $j('#akel-nav');
    }
    nav.addClass('animating');
    nav.removeClass('slide');
    nav.find('li.communities>a, li.find-a-home>a').find('span.fa-minus').addClass('fa-plus').removeClass('fa-minus');
    clearTimeout(cnavout);
    clearTimeout(accentoutview);
    cnavout = setTimeout(function() {
        nav.removeClass('com-open');
        nav.find('li.communities, li.find-a-home').removeClass('open');
        hidenav = setTimeout(function() {
            nav.find('li.communities>a, li.find-a-home>a').next('ul').stop(true,false).slideUp(500, 'easeInOutExpo');
            clearTimeout(hidenav);
        }, 200);
        nav.removeClass('animating');
    }, 500);
    clearloader = setTimeout(function() {
        nav.removeClass('preload');
    }, 1000);
    nav.find('.mobile-accent-frame').each(function(index){
        var accent = $j(this);
        accentoutview = setTimeout(function() {
            accent.removeClass('inview');
        }, 100 * (index + 1));
    });
}
function comnavIntro(type) {
    var nav = $j('#nav');
    if (type != 'nav') {
        var nav = $j('#akel-nav');
    }
    nav.addClass('animating');
    nav.addClass('com-open');
    clearTimeout(cnavin);
    clearTimeout(accentinview);
    cnavin = setTimeout(function() {
        nav.addClass('slide');
        nav.removeClass('animating');
        nav.find('.mobile-accent-frame').each(function(index){
            var accent = $j(this);
            accentinview = setTimeout(function() {
                accent.addClass('inview');
            }, 100 * (index + 1));
        });
    }, 200);
}
