function toolbar() {

    $j('.tags .btn').on('click keydown', function(e) {
        if (e.type === 'click' || e.key === 'Enter') {
            e.preventDefault();
            const $parent = $j(this).parent();
            const isExpanded = $parent.hasClass('open');
            $parent.toggleClass('open');
            $j(this)
                .attr('aria-expanded', !isExpanded)
                .next('.tags-list')
                .attr({
                    'aria-hidden': isExpanded,
                    'role': 'menu',
                    'aria-label': 'Tags menu'
                })
                .toggleClass('hidden')
                .find('a').attr('role', 'menuitem');
            if (!isExpanded) {
                $j('.open').not($parent).removeClass('open')
                    .find('button')
                    .attr('aria-expanded', 'false')
                    .next('.tags-list')
                    .attr('aria-hidden', 'true')
                    .addClass('hidden');
            }
        }
     });
     $j(document).on('keyup', function(e) {
        if (e.key === 'Escape') {
            const $openMenus = $j('.open');
            if ($openMenus.length) {
                const $button = $openMenus.find('button');
                $button.attr('aria-expanded', 'false').focus();
                $openMenus.removeClass('open')
                    .find('.tags-list')
                    .attr('aria-hidden', 'true')
                    .addClass('hidden');
            }
        }
     });
     $j('.tags-list').on('focusout', function(e) {
        const $list = $j(this);
        if (!$list.has(e.relatedTarget).length) {
            const $button = $list.prev('button');
            $button.focus();
            $list.addClass('hidden')
                .attr('aria-hidden', 'true');
            $button.attr('aria-expanded', 'false')
                .parent().removeClass('open');
        }
     });


    $j('.toolbar .cats button').on('click keydown', function(e) {
        if (e.type === 'click' || e.key === 'Enter') {
            e.preventDefault();
            const $parent = $j(this).parent();
            const isExpanded = $parent.hasClass('open');
            $parent.toggleClass('open');
            $j(this)
                .attr('aria-expanded', !isExpanded)
                .next('ul')
                .attr({
                    'aria-hidden': isExpanded,
                    'role': 'menu',
                    'aria-label': 'Category menu'
                })
                .toggleClass('hidden')
                .find('li a').attr('role', 'menuitem');
            if (!isExpanded) {
                $j('.toolbar .cats .open').not($parent).removeClass('open')
                    .find('button')
                    .attr('aria-expanded', 'false')
                    .next('ul')
                    .attr('aria-hidden', 'true')
                    .addClass('hidden');
            }
        }
    });
    $j(document).on('keyup', function(e) {
        if (e.key === 'Escape') {
            const $openMenus = $j('.toolbar .cats .open');
            if ($openMenus.length) {
                const $button = $openMenus.find('button');
                $button.attr('aria-expanded', 'false').focus();
                $openMenus.removeClass('open')
                    .find('ul')
                    .attr('aria-hidden', 'true')
                    .addClass('hidden');
            }
        }
    });
    $j('.toolbar .cats ul').on('focusout', function(e) {
        const $list = $j(this);
        if (!$list.has(e.relatedTarget).length) {
            const $button = $list.prev('button');
            $button.focus();
            $list.addClass('hidden')
                .attr('aria-hidden', 'true');
            $button.attr('aria-expanded', 'false')
                .parent().removeClass('open');
        }
    });


}
