function insta() {

    if ( ! $j('.igfeed').length ) {
        return;
    }
    var
    feedurl = $j('.igfeed').data('url');
    counter = 0,
    max = 7,
    ightml = '';

    $j.get(feedurl)
    .done(function(data){
        if (typeof data !== 'object' || data === null || data.error) {
            $j('.igfeed>div>div.media>ul>li.placeholder').remove();
            return;
        }
        $j.each( data.data, function( key, post ) {
            ++counter;
            if ( counter > max )  {
                return;
            }
            var icon = '<b class="fa-solid icon-type fa-camera"></b>';
            if ( post.media_type == 'VIDEO' ) {
                post.media_url = post.thumbnail_url;
                icon = '<b class="fa-solid icon-type fa-video"></b>';
            }
            ightml += '\
            <li class="ig"><div>\
            <a href="'+post.permalink+'" class="b-lazy media" target="_blank" aria-label="View on Instagram" data-src="'+post.media_url+'" aria-label="Instagram Post">\
            '+icon+'\
            <span class="timestamp">'+timeAgo(post.timestamp)+'</span>\
            <i class="cover">\<b class="fa-brands icon fa-instagram"></b></i>\
            </a>\
            <i class="hover"></i><span class="img-loader light"><span class="animation"><svg class="circular" viewBox="25 25 50 50"><circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"></circle></svg></span></span></div>\
            </li>';
        });
        $j('.igfeed>div>div.media>ul>li.placeholder').remove();
        $j('.igfeed>div>div.media>ul').prepend(ightml);
        $j('.igfeed li.ig>div').hoverdir();
        bLazy.revalidate();
        return;
    })
    .fail(function() {
        $j('.igfeed>div>div.media>ul>li.placeholder').remove();
        return;
    });
    function timeAgo(timestamp) {
        var now = new Date();
        var date = new Date(timestamp);
        var secondsPast = (now.getTime() - date.getTime()) / 1000;
        if (secondsPast < 60) { return parseInt(secondsPast) + ' seconds ago'; }
        if (secondsPast < 3600) { return parseInt(secondsPast / 60) + ' minutes ago'; }
        if (secondsPast <= 86400) { return parseInt(secondsPast / 3600) + ' hours ago'; }
        if (secondsPast > 86400) {
            var day = parseInt(secondsPast / 86400);
            if (day === 1) { return 'Yesterday'; }
            if (day < 7) { return day + ' days ago'; }
            if (day < 30) {
                var week = parseInt(day / 7);
                if (week === 1) { return '1 week ago'; }
                return week + ' weeks ago';
            }
            if (day >= 30) {
                var month = parseInt(day / 30);
                if (month === 1) { return '1 month ago'; }
                return month + ' months ago';
            }
        }
    }

    $j('.yt-video-card .media').hoverdir();
}
