(function ($, root, undefined) {
    $(document).ready(function() {
        $('html').removeClass('no-js');
        updateAction();
        lightboxes();
        mobile();
        nav();
        tabNav();
        locationMap();
        ajaxForm();
        akelWarranty();
        tracking();
        designCenter();
        swipers();
        akelCookies();
        loader();
        floorplanFilters();
        insta();
        countdown();
        booking();
        mcalc();
		singleFloorplan();
		akelLogos();
		faqs();
		blocks();
		animations();
		scrollProgress();
        updateNav();
        updateAction();
        walkIn();
        calendarWidget();
        hero();
        toolbar();
        if ( isSafari() ) {
            //$j('body').addClass('safari');
        }
    });
})(jQuery, this);


